import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
// import { styled, css } from '@mui/system';
import CookieIcon from '@mui/icons-material/Cookie';
import Typography from '@mui/material/Typography';
import { reinit } from '../../i18n.js';

import './styles.css';



const CookiesConsent = () => {
  const { t } = useTranslation();

  const [anchor, setAnchor] = useState(null);
  const [cookiesDeclined, setCookiesDeclined] = useState();

  const handleClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const onAccept = () => {
    window.globalCookies.gtag('consent', 'default', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
    });
    handleClick();
    reinit();
    localStorage.setItem('cookiesAccepted', true);
    document.getElementById('cookiesbox').hidden = true;
  }

    const onDecline = () => {
    window.globalCookies.gtag('consent', 'default', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied'
    });
    document.cookie = null;
    localStorage.clear();
    setCookiesDeclined(true);
    handleClick();
  }

  const open = Boolean(anchor);
  // const id = open ? 'simple-popup' : undefined;


  const cookiesAccepted = localStorage.getItem('cookiesAccepted');

  if (!!cookiesAccepted || !!cookiesDeclined) {
    return;
  } 
    

  return (
    <div id="cookiesbox" className='cookie'>
      <button aria-describedby='cookies' type="button" onClick={handleClick}>
        <CookieIcon sx={{ color: '#16b1ff' }}/>
      </button>
      <BasePopup id='cookies' open={open} anchor={anchor}>
        <div className='message'>
          {/* <p>We use cookies for Google Analytics and language preferences</p> */}
            <Typography component="div">
              {/* {t(texts[1])} variant="span" */}
              {t('cookies')}
            </Typography>
          <div className='buttons' align='center'>
            <input value={t('accept')}  type='button' onClick={onAccept}/>
            <input value={t('decline')} type='button' onClick={onDecline}/>
          </div>
        </div>
      </BasePopup>
    </div>
  );
}

export default CookiesConsent;
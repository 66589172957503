import { memo } from 'react';
import { useTranslation, Trans } from "react-i18next";

import www from '../../assets/images/iconos/www.png';
import app from '../../assets/images/iconos/app.png';
import code from '../../assets/images/iconos/code.png';
import BlueWhiteTitle from '../BlueWhiteTitle';
import './styles.css';

const services = {
  custom: {
    img: code,
    title: 'SOFTWARE SOLUTION',
    subtitle: 'Custom software development',
    desc: `We cant to see your vision become a reality. Let's find the right software solution for your needs!
    
We specialize in:`,
    list: ['JS', 'React', 'React Native'],
  },
  web: {
    img: www,
    title: 'OUR SERVICES',
    subtitle: 'Web development',
    desc: `The web you've always wanted is within reach! Our services include:`,
    list: ['Tailored solutions', 'Mobile view adapted', 'Multilingual', 'Routing', 'Eco-friendly hosting'],
  },
  mobile: {
    img: app,
    title: 'CUSTOMIZED APPS',
    subtitle: 'Mobile Apps',
    desc: 'Built using React Native technology for customized apps:',
    list: ['Cost-effective', 'Cross-platform unified code'],
  }
}


const Service = ({type, title, subtitle, desc, list = []}) => {
  const { t } = useTranslation();

  return (
    <div className='servicecard'>
        <div className='servleft'>
          <div>
            <img src={type.img}/>
          </div>
        </div>
        <div className='servright'>       
          <BlueWhiteTitle title={type.title} subtitle={type.subtitle}/>

          <div className='smalltext'>
            {type.desc}
          </div>
          <ul>
          {type.list.map( e => {
            return (
              <li>{e}</li>
            )
          })}
          </ul>
          
          
          {/* <>
          <hr/>
          </> */}
        </div>
      </div>
    );
}


const Grid = ( { title } ) => {

  return (
    <div className='grid'>
        <div className='gridleft'>
          <div className='gridimg'></div>
          <Service type={services.web}/>
        </div>
        <div className='gridright'>       
          <Service type={services.custom}/>
          <Service type={services.mobile}/>
        </div>
      </div>
    );
}

export default memo(Grid);
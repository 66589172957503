import { memo } from 'react';
import './styles.css';


const BlueWhiteTitle = ( { title, subtitle } ) => {

  return (
    <div className='bwtitol'>
        <div>{`// ${title}`}</div>
        <span>{subtitle}</span>
        <hr/> 
    </div>
  );
}

export default memo(BlueWhiteTitle);
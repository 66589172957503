import { memo } from 'react';
import { useTranslation, Trans } from "react-i18next";

import './styles.css';


const ScreenHeader = ( { title } ) => {
  const { t } = useTranslation();

  return (
    <div className='screenHeader'>
        <div className='bg'>
          <span className='headerTitle'>
            {t(title)}
          </span>
        </div>
    </div>
  );
}

export default memo(ScreenHeader);
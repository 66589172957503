import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect';

// import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadPolygonPath } from "@tsparticles/path-polygon";
import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
// import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

import { optionsjson } from '../assets/particles';

import Square from "../components/Square";
import { Section } from "../components/Section";
import Box from '../components/Box';
import DesignRow from '../components/DesignRow';

// import greenseal from '../assets/images/ggseal0.png';
import icon from '../assets/images/icons/light.png';
import wtw from '../assets/images/logos/wtwwhite.png';
import veroink from '../assets/icons/veroinkwhite.png';
import espainam from '../assets/icons/espainam.png';
import sandga from '../assets/icons/sandgaWhite.png';
import green from '../assets/images/ggseal0.png';
import filter from '../assets/icons/filter.png';
import eco from '../assets/icons/eco.png';
import central from '../assets/icons/central.png';




import { espainamurl, veroinkurl } from '../constants';


export const Home = () => {
    const { t } = useTranslation();
  
    // this should be run only once per application lifetime
      useEffect( () => {
        if (window?.tsParticles) {
          window.tsParticles
          .load({
            id: "tsparticles",
            // url: './particles.json',
            options: { 
              preset: "links",
              speed: 1,
              particles: {
                number: {
                  density: {
                    enable: false,
                  },
                  value: isMobile ? 20 : 30
                }
              }
            }
          })
          .then(container => {
            //console.log("Loaded", isMobile);
          })
          .catch(error => {
            console.error(error);
          });
        }
          const bdy = document.getElementsByTagName('body')[0];
          if (bdy) bdy.className='darkbg';
      }, [window?.tsParticles]);


    return (
      <main>
        <div>
          <Square title={"eslogan"}>
            <div className="boxesRow">
              <Box icon={filter} title={'filter.title'} text={'filter.text'}></Box>
              <Box icon={eco} title={'eco.title'} text={'eco.text'}></Box>
              <Box icon={central} title={'central.title'} text={'central.text'}></Box>
            </div>
          </Square>  
          <DesignRow/>
          <DesignRow variant={2}/>
          <DesignRow variant={3}/>

          <Section 
            // text='desc' 
            // img1={icon} 
            // text2="desc2"
            // img2={green} 
            // text3="desc3" 
            // text3="products" 
            img3={[{
              img: wtw,
              url: 'https://www.whattowatch.xyz'
            }]}
            text4="clients"
            imgs4={[veroink, espainam]}
            urls4={[veroinkurl, espainamurl]}
            secondRow={[{
              img: sandga,
              url: 'https://www.sandga.com'
            }]}
            textvar="https://www.greengeeks.com/" 
            text2var="https://www.greengeeks.com/going-green"/>
        </div>
      </main>
    );
  };
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import rocket from '../assets/images/iconsMis/rocket.png';

import './styles.css';

export const Section = (
    { img1, img2, img3, text, textvar, text2, text2var, text3,
      text4, imgs4, urls4, secondRow}
  ) => {

  const { t } = useTranslation();

  const comps = [
    <a href={textvar} target="_blank" rel="noreferrer"/>, 
    <h3 align="center" />,
    <a href={text2var} target="_blank" rel="noreferrer"/>
  ];

  const imagesBlock = imgs4.map((logo, i) => {
    const style = {
      filter: 'grayscale(1) drop-shadow(3px 3px 7px white)'
    };

    return (
        <a key={`img_${i}`} target="_blank" href={urls4[i]} rel="noreferrer">
          <img height="75" src={logo}/>
        </a>
    );
  });

  const getBrandsRow = (items) => {
    return items.map((item, i) => {
  
      return (
          <a key={`img_${i}`} target="_blank" href={item.url} rel="noreferrer">
            <img height="75" src={item.img}/>
          </a>
      );
    });
  }

  const hostingRow = getBrandsRow(secondRow);
  const productsBlock = getBrandsRow(img3);


  const animation = () => {
    document.getElementById('rocket').className = 'rocket';
  }

  /**  style={{filter: 'drop-shadow(2px 4px 6px black)'}} */
  return (
    <div className="box">
        {/* <h5>{t(title)}</h5> */}
        <div>
          <br/>
          <div className="homeImage" onClick={animation}>
            <img id='rocket' src={rocket}/>
          </div>
          {
            !!text && (
              <div>
                <Trans i18nKey={text} components={comps} />
                <div className="homeImage">
                  <img src={img1}/>
                </div>
              </div>
            )
          } 
          {
            !!text2 && (
              <div>
                <Trans i18nKey={text2} components={comps} />
                <div className="homeImage">
                  <img src={img2}/>
                </div>
              </div>
            )
          }

          <div className='bottom'>
            {!!text2 && (<div className="homeImage">
              <Trans i18nKey={text3} components={comps}/>
            </div>)}
            <h6>what to watch</h6>
            <div className='clients'>
              {productsBlock}
            </div>
            
            <hr color='white'/>

            <h6>web development</h6>
            <div className='clients'>
              {imagesBlock}
            </div>
            <h6>hosting</h6>
            <div className='clients'>
              {hostingRow}
            </div>
          </div>
        </div>
    </div>
  );
  
};
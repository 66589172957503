import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";

import Card from "../components/Card";
import ScreenHeader from '../components/ScreenHeader';
import BlueWhiteTitle from '../components/BlueWhiteTitle';
import Grid from '../components/Grid';

import js from "../assets/icons/jschroma.png";
import react from "../assets/icons/reactjsicon.webp";
import rn from "../assets/icons/rnicon.png";

import './styles.css';

export const Services = () => {
    const { t } = useTranslation();

    // card text (keys): suggested for, title, cost, description
    const basic = [
      'ecohosting0a',
      'ecohosting0b',
      'ecohosting0c',
      'ecohosting0d',
    ];
    const wp = [
      'ecohosting1a',
      'ecohosting1b',
      'ecohosting1c',
      'ecohosting1d',
    ];
    return (
      <>
        <main className='contactbg'>
          <ScreenHeader title={t("services")}/>
          <div className='contactBox'>
            <BlueWhiteTitle title={'THE FUTURE'} subtitle={'Eco-hosting'}/>
            <div className='smallText marginTop'>
              <p>
                Many people don't know the impact that the internet has on the environment. Did you know it's one of the world's biggest polluters?
              </p>
              <br/>
              <span>To counteract the damaging effects, Barcinotech exclusively uses eco-friendly hosting via GreenGeek.</span>
            </div>
          </div>
          <Grid/>

          {/* <div>
            <br/><br/> <br/>
            <h2>🌱 {t("hosting")} 🌱</h2>
            <br/><br/>
            <h3>{t("ecohosting")}</h3>
            <br/>
            <div className='ecohosting'>
              <Card texts={basic} />
              <Card texts={wp} />
              <Card texts={premium} />}
            </div>
            
          </div> */}

          <br/><br/><br/><br/><br/><br/><br/><br/>

          <hr style={{width:'65%'}}/>
          <br/><br/><br/><br/>
          <h5>{t("specialized")}</h5>
          <br/><br/><br/>
          <div className='specialized'>
            <img height="75" src={js} />
            <img height="75" src={react} />
            <img height="75" src={rn} />
          </div>

          <br/><br/><br/><br/>

          {/* <div className='webdev'>
            <hr align="center" style={{width:'70%'}}/>
            <br/>
            <h2>{t("serveis")}</h2>
            <ul>
              <li>
                <span>{t("list0")}</span>
              </li>
              <li>
                <span>{t("list1")}</span>
              </li>
              <li>
                <span>{t("list2")}</span>
              </li>
              <li>
                <span>{t("list3")}</span>
              </li>
              <li>
                <span>{t("list4")}</span>
              </li>
            </ul>
          </div> */}


        </main>
      </>
    );
  };
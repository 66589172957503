const translations = {
    ca: {
        translation: {
            filter: {
                title: 'Personalització',
                text: `Tu ho somies, nosaltres ho creguem.
Adaptar-nos a les diferents necessitats i crear productes basats en les teves idees és el que fem millor.`,
            },
            eco: {
                title: 'Sostenibilitat',
                text: `Com un dels nostres valors fonamentals, creiem en un enfocament sostenible per als serveis de desenvolupament web, reduint la bretxa entre la tecnologia i les bones pràctiques ambientals per reduir el consum humà i la petjada de carboni.`,
            },
            central: {
                title: 'Centralització',
                text: `Posem focus en la centralització com lʻopció més eficient per a aplicacions multiplataforma, per obtenir resultats més consistents i escalables, així com una millor experiència dʻusuari.`,
            },
            designrow3: {
                header: `Tu ho somies, nosaltres ho creguem`,
                desc: 'Adaptar-nos a les diferents necessitats i crear productes basats en les teves idees és el que fem millor',
            },
            contactScreen: {
                black: `LA TECNOLOGÍA, LA CREATIVITAT Y LA SOSTENIBILITAT PODEN UNIR-SE. ¿ESTÀS PREPARAT PER COMENÇAR?`,
                blue: `Posa't en contacte amb nosaltres, estarem encantats de rebre notícies vostres!`
            }
        }
    },
    en: {
        translation: {
            filter: {
                title: 'Personalization',
                text: `You dream it, we code it.
Adapting to different needs and building products based on your input is what we do best.`,
            },
            eco: {
                title: 'Sustainability',
                text: `As one of our core values, we believe in sustainable approach to web development services, bridging the gap between technology and good environmental practices to reduce pur, and ours clients' carbon footprint.`,
            },
            central: {
                title: 'Centralization',
                text: `We focus on centralization as the most efficient option for cross-platform applications, for more consistent and scaleable results, as well as better user experience.`,
            },
            designrow3: {
                header: `You dream it, we code it`,
                desc: 'Adapting to different needs and building products based on your input is what we do best',
            },
            contactScreen: {
                black: `TECHNOLOGY, CREATIVITY, AND SUSTAINABILITY CAN COME TOGETHER. ARE YOU READY TO GET STARTED?`,
                blue: `Get in touch today, we can't wait to hear from you!`
            }
        }
    },
    es: {
        translation: {
            filter: {
                title: 'Personalización',
                text: `Tú lo sueñas, nosotros lo creamos.
Adaptarnos a las diferentes necesidades y crear productos basados en tus ideas es lo que mejor hacemos.`,
            },
            eco: {
                title: 'Sostenibilidad',
                text: `Como uno de nuestros valores fundamentales, creemos en un enfoque sostenible para los servicios de desarrollo web, reduciendo la brecha entre la tecnología y las buenas prácticas ambientales para reducir el consumo humano y la huella de carbono.`,
            },
            central: {
                title: 'Centralización',
                text: `Ponemos foco en la centralización como la opción más eficiente para aplicaciones multiplataforma, para obtener resultados más consistentes y escalables, así como una mejor experiencia de usuario.`,
            },
            designrow3: {
                header: 'Tú lo sueñas, <0>nosotros lo creamos</0>',
                desc: 'Adaptarnos a las diferentes necesidades y crear productos basados en tus ideas es lo que mejor hacemos',
            },
            contactScreen: {
                black: `LA TECNOLOGÍA, LA CREATIVIDAD Y LA SOSTENIBILIDAD PUEDEN UNIRSE. ¿ESTÁS LISTO PARA COMENZAR?`,
                blue: `Contacta con nosotros, estaremos encantados de atenderte!`
            }
        }
    }

};

export default translations;
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { NavLink } from "react-router-dom";
import i18n from 'i18next';
import { motion, useCycle } from "framer-motion";

import { LANGUAGES } from "../../constants";
import logo from '../../assets/images/logo.png';
import './styles.css';
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";


export const Header = () => {
  const [isOpen, setIsOpen] = useCycle(false, true);
  const [isClosedAndFinished, setIsClosedFinished] = useState(true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  useEffect( () => {
    const main = document.getElementsByTagName('main')[0];
    if (main) main.addEventListener('click', outsideClick);

    return () => {
      if (main) main.removeEventListener('click', outsideClick);
    }
  }, [isOpen]);

  const outsideClick = useCallback( () => {
    if (isOpen) handleToogle();
  }, [isOpen]);

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: "circle(20px at 40px 35px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  };

  // this fix the background click trigger even menu is closed (happening also in demos of library)
  const handleToogle = () => {

    if (isOpen) { // abril
      setTimeout( () => setIsClosedFinished(isOpen), 900);
    } else { // cerral
      setIsClosedFinished(false);
    }
    setIsOpen(isOpen => !isOpen);
  };

  return (
    <div className='header'>
      <div className="logoheader">
          <div></div>
          <div>
            <NavLink to="/">
              <img src={logo} height={60} className="logo" alt="logo" />
            </NavLink>
          </div>
          <div>
            <select className="langs" defaultValue={i18n.resolvedLanguage} onChange={onChangeLang}>
            {LANGUAGES.map(({ code, label }) => (
              <option key={code} value={code}>
                {label}
              </option>
            ))}
          </select>
          </div>   
      </div>

        <motion.nav
          initial={false}
          animate={isOpen ? "open" : "closed"}
          custom={height}
          ref={containerRef}
        >
          <div onClick={() => handleToogle()} className={isClosedAndFinished ? 'hidden' : 'menuContainer'}>
            <motion.div id="menuarea" className="background" variants={sidebar} />
            <Navigation />
          </div>
          <MenuToggle id="toogleButton" toggle={handleToogle} />          
        </motion.nav>
    </div>
  )
}

const onChangeLang = (e) => {
  const lang_code = e.target.value;
  i18n.changeLanguage(lang_code);
};
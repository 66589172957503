import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';

import ScreenHeader from '../components/ScreenHeader';
import BlueWhiteTitle from '../components/BlueWhiteTitle';


export const Contact = () => {
    const { t } = useTranslation();

    const [messageSentState, setMessageSent] = useState(0);
    const [errorMsg, setErr] = useState('initial');

    ///

    const sendMail = (event) => {
          event.preventDefault();
          const form = document.getElementById('contact-form');
          setMessageSent(1);
          // event.preventDefault();
          // generate a five digit number for the contact_number variable
          form.contact_number.value = Math.random() * 100000 | 0; // como id
          // these IDs from the previous steps
          emailjs.sendForm('contact_service', 'contact_form', form, 'UxjFLLoYe05cFexNB')
              .then(function() {
                  console.log('Message sent');
                  setErr('sentttttt');
                  setMessageSent(2);
              }, function(error) {
                  console.log('FAILED...', error);
                  setMessageSent(-1);
                  setErr(error);
              });
    }
    ///

    useEffect( () => {
      emailjs.init('UxjFLLoYe05cFexNB');
    }, []);


    return (
      <main className='contactbg'>
        <ScreenHeader title={t("contact")}/>

        {(messageSentState === 0) && 
          (
            <div className='contactBox'>
              <BlueWhiteTitle title={t("contactScreen.black")} subtitle={t("contactScreen.blue")}/>

              <form id="contact-form" onSubmit={sendMail}>
                  {/* ID MESSAGE - hidden  */}
                  <input type="hidden" name="contact_number" />
                  <input placeholder={t("form0")}  name="from_name" required/>
                  <input placeholder={t("form1")} type="email" name="reply_to" required/>
                  <textarea placeholder={t("form2")} name="message" required></textarea>
                  <input id="input-submit" 
                          type="submit" 
                          value={t("form3")} 
                          style={{cursor:'pointer'}}/>
              </form>
            </div>
          )
        }

        {(messageSentState === -1) && 
          (
              <strong>{errorMsg}</strong>
          )
        }
        {(messageSentState === 1) && 
          (
            <div className="container">
              <div className="spinner"/>
            </div>
          )
        }
        {(messageSentState === 2) && 
          (
            <div className="confirm">
              <br/><br/><br/>
              <h4>{t("confirm0")}</h4>
              <br/>
              <br/>
              <h3>{t("confirm1")}</h3>
              <br/>
              <h3>{t("confirm2")}</h3>
            </div>
          )
        }
      </main>
    );
  };
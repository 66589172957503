import * as React from 'react';
import { useTranslation } from "react-i18next";

import './styles.css';


const Box = ( { title, text, icon } ) => {
  const { t } = useTranslation();

  return (
    <div className='headerBox'>
        <img className='topIcon' src={icon}/>
        <div className='title'>
            {t(title)}
        </div>
        <p className='smallText'>
            {t(text)}
        </p>
    </div>
  );
}

export default Box;